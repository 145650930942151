<template>
  <div class="constrain part-intern downloads-intern">
    <div class="row-12 mb-16">
      <div class="md:col-9 md:offset-3 border-b-2 border-lightblue pb-16 flex flex-col">
        <h4 v-if="payload.titel" class="mb-8" v-html="payload.titel" />
        <div v-if="payload.text" class="description text-navy mb-8" v-html="payload.text" />
        <Go
          v-for="file in files"
          :key="file.id"
          :to="file.datei[0]?.link"
          class="file-item mb-6 flex items-center self-start"
          target="_blank"
        >
          <File />
          <div class="ml-6 md:ml-4">
            <p class="text-navy" v-html="file.datei[0]?.name.split('.')[0]" />
          </div>
        </Go>
      </div>
    </div>
  </div>
</template>

<script>

import File from '../../assets/images/file.svg';

export default {
  components: {
    File,
  },
  props: {
    payload: Object,
  },
  computed: {
    files() {
      return this.payload.dateien;
    },
  },
  mounted() {
    // console.log(this.payload);
    // console.log(this.files);
  },
};
</script>

<style lang="scss" scoped>
.file-item {
  p {
    background-image: linear-gradient(var(--color-navy), var(--color-navy));
    background-size: 0 px(2);
    background-repeat: no-repeat;
    background-position: bottom left;
    will-change: background-size;
    transition: background-size .25s ease-out;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover p {
      background-size: 100% px(2);
      color: var(--color-navy);
      }
    }
    svg {
    width: px(30);
    height: px(30);
    @screen md {
      width: px(40);
      height: px(40);
    }
    @screen lg {
      width: px(45);
      height: px(45);
    }
  }
}
</style>
